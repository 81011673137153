<template>
  <section class="page-breadcumb-view">
    <div class="container">
      <h2 class="breadcumb-view-heading">
        {{ title }}
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    title: String,
  },
}
</script>

<style>

</style>
